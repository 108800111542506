































import {Vue, Component} from "vue-property-decorator";
import {apiUserDeviceLists} from "@/api/user/user";
import {RequestPaging} from "@/utils/util";
import LsPagination from "@/components/ls-pagination.vue"

@Component({
  components: {
    LsPagination
  }
})
export default class UserDevice extends Vue {

  form: any = {
    keyword: ''
  }
  // 设备列表
  deviceList = [];
  pager: RequestPaging = new RequestPaging();

  created() {
    this.getUserDeviceLists()
  }

  /**
   * 获取用户分页
   * 默认获取第一页，点击页码后获取当页
   */
  async getUserDeviceLists() {
    await
        this.pager
            .request({
              callback: apiUserDeviceLists,
              params: {
                ...this.form,
              },
            }).then((res: any) => {
          const {lists} = res
          this.deviceList = lists
        })
  }

// 跳转详情页
  detailsClick(row: any) {
    this.$router.push({
      path: '/user/device_details',
      query: {
        user_id: row.id,
      }
    })
  }
}
